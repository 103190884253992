import React, { useContext } from 'react';
import 'animate.css';
import { Link } from 'gatsby';
import { Row, Col, Stack } from 'react-bootstrap';
import { Header, Footer } from 'components/theme';
import { Container, Button } from 'components/common';
import CookieConsent from 'react-cookie-consent';
import element55 from 'assets/illustrations/Element55.png';
import element56 from 'assets/illustrations/Element56.png';
import element57 from 'assets/illustrations/Element57.png';
import fairies from 'assets/illustrations/FAIRIES2.svg';
import servicepaketelarge from 'assets/illustrations/SERVICEPAKETE-LARGE.svg';
import neuGewerbe from 'assets/illustrations/NEU-GRAPHIC.svg';

import {
  GewerbekundenWrapper,
  GewerbeWrapper,
  PrivatKundenWrapperDetails,
  HeroImageGewerbe,
  ImageBackgroundGewerbe,
  Section1Gewerbe,
  Section2Gewerbe,
  Section3Gewerbe,
  Section4Wrapper,
  Section5Wrapper,
  Section4BackgroundGewerbe,
  Section5Background,
} from '../components/styles/styles';

export default function About() {
  function getContactForm() {
    const id = document.getElementById('kontaktButton');
    if (id) {
      document.getElementById('homeButton').click();
      setTimeout(function() {
        document.getElementById('kontaktButton').click();
      }, 0);
    }
  }
  return (
    <>
      <GewerbekundenWrapper>
        <Header />
        <HeroImageGewerbe>
          <GewerbeWrapper as={Container} className="positionRelative">
            <PrivatKundenWrapperDetails>
              <Stack gap={3}>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title1Gewerbe">GEWERBE</h4>
                  </Col>
                </Row>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title2Gewerbe">KUNDEN</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a href="mailto:office@rd-hausfee.at">
                      <Button className="buttonAnfrageGewerbe">ANFRAGE</Button>
                    </a>
                    <Link to="/">
                      <Button className="buttonAngebotGewerbe" onClick={getContactForm}>
                        ANGEBOT EINHOLEN
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Stack>
            </PrivatKundenWrapperDetails>
            <Row className="positionAbsolute">
              <img
                className="servicepaketeGewerbe animate__animated animate__flipInX"
                src={servicepaketelarge}
                alt="Servicepakete"
                height="263"
              />
            </Row>
          </GewerbeWrapper>
        </HeroImageGewerbe>
        <GewerbeWrapper as={Container} className="positionRelative">
          <Section1Gewerbe>
            <div className="sectionP1">
              <h2 className="dancingScript64Gewerbe">
                Wir reinigen und pflegen Ihre Gewerbeimmobilie, Zinshaus und vieles mehr.
              </h2>
              <p className="latoRegular36">
                Wir bieten kompetente, präzise Reinigung für unterschiedliche Gewerbeimmobilien. Wir reinigen
                Zinshäuser, Mietobjekte, Stiegenhäuser, Gemeinschaftsflächen in Wohnhäusern und vieles mehr. Eine
                regelmäßige, gründliche Reinigung erhält den Wert Ihrer Immobilie und lädt Mitarbeiter, Kunden und
                Bewohner dazu ein, sich wohlzufühlen.
              </p>
            </div>
          </Section1Gewerbe>
        </GewerbeWrapper>
        <ImageBackgroundGewerbe>
          <GewerbeWrapper as={Container} className="positionRelative">
            <Section2Gewerbe className="paddingBottom80">
              <Row>
                <Col xs="12">
                  <h3 className="dancingScript56">
                    Professionelle Reinigung <br /> von Gewerbeimmobilien
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <p className="latoRegular21White">
                    Wir reinigen Ihre Gewerbeimmobilie kompetent und gründlich um ein angenehmes Raumklima zu schaffen,
                    in dem sich alle wohl fühlen. Wohnhäuser, Stiegenhäuser, den Straßenbereich und vieles mehr.
                  </p>
                </Col>
                <Col md="6" xs="12">
                  <p className="latoRegular21White">
                    Außerdem bieten wir Grundreinigungen nach Sanierung/Renovierung und einmalige Gesamtreinigungen
                    (Frühjahrsputz) an.
                  </p>
                </Col>
              </Row>
            </Section2Gewerbe>
          </GewerbeWrapper>
        </ImageBackgroundGewerbe>
        <div className="height1520">
          <GewerbeWrapper as={Container} className="positionRelative">
            <Section3Gewerbe>
              <div className="boxAbout"></div>
              <Row>
                <Col md="6" sm="8" xs="12" lg="4" className="marginRight53 zIndex99">
                  <img className="element55" src={element55} alt="Cleaning gloves" />
                </Col>
                <Col md="6" xs="12" className="zIndex99">
                  <div>
                    <h3 className="latoRegular36">
                      Sie legen wert auf <br /> saubere Gemeinschaftsflächen?
                    </h3>
                    <p className="latoRegular21">
                      Die Bewohner Ihres Objekts werden es Ihnen danken! Sauberkeit und Ordnung erfreuen Mieter,
                      steigern die Zufriedenheit und verbessern das Wohnklima. Auf lange Sicht erhalten Sie dadurch eine
                      zuverässige Bindung Ihrere Mieter.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="marginBottom384">
                <Col md="6" xs="12" className="zIndex99">
                  <img className="element56" src={element56} alt="Cleaning team" />
                </Col>
                <Col md="6" xs="12" className="section3Right zIndex99">
                  <h3 className="latoRegular36">
                    Sie vermieten <br /> Wohnungen?
                  </h3>
                  <p className="latoRegular21">
                    Zuverlässig und gründlich reinigen und pflegen wir Stiegenhäuser und Gemeinschaftsflächen. Darunter
                    fallen zum Beispiel Keller, Garagen und der Straßenbereich vor dem Wohnhaus.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12" className="zIndex99">
                  <h3 className="latoRegular36">
                    Sauberkeit <br /> nach Sanierung?
                  </h3>
                  <p className="latoRegular21">
                    Sie haben Ihre Immobilie umfassend saniert/renoviert und möchten diese jetzt beziehen oder
                    vermieten? Wir führen eine Grundreinigung durch und Sie können sich sorgenfrei um alles andere
                    kümmern. Sie erhalten Ihre Immobilie rein und ordentlich zurück.
                  </p>
                </Col>
                <Col md="6" xs="12" className="positionRelative zIndex99">
                  <img className="neuGewerbe" src={neuGewerbe} alt="NEU!" height="263" />
                  <img className="element57" src={element57} alt="Hands" />
                </Col>
              </Row>
            </Section3Gewerbe>
          </GewerbeWrapper>
        </div>
        <Section4BackgroundGewerbe>
          <GewerbeWrapper as={Container} className="paddingBottom53">
            <Section4Wrapper>
              <Stack>
                <div>
                  <Stack className="paddingTop160 positionRelative">
                    <div>
                      <h2 className="dancingScript64">
                        Wir pflegen Ihre Gewerbeimmobilie und tragen so maßgeblich zur Werterhaltung bei.{' '}
                      </h2>
                      <img className="fairies1Gewerbe" src={fairies} alt="Fairies" />
                      <p className="latoRegular36White">
                        Sie haben Fragen zu unseren Leistungen oder wünschen sich sofort eine gute Fee? Vereinbaren Sie
                        noch heute ein kostenloses Erstgespräch!
                      </p>
                    </div>
                  </Stack>
                  <Stack className="positionRelative">
                    <Button className="buttonKontaktGewerbe" onClick={getContactForm}>
                      KONTAKT
                    </Button>
                  </Stack>
                </div>
              </Stack>
            </Section4Wrapper>
          </GewerbeWrapper>
        </Section4BackgroundGewerbe>
        <Section5Background>
          <Footer />
        </Section5Background>
      </GewerbekundenWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#95c357' }}
        buttonStyle={{ color: '#95c357', fontSize: '13px', background: '#fff' }}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in
        unserer <a href="/impressum">Datenschutzerklärung</a> am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um
        Cookies zu akzeptieren.
      </CookieConsent>
    </>
  );
}
